function initBannerCarousel() {
  const bannerList = window.banner;
  const indexContainer = $(".carousel.slide .carousel-inner");
  const indicatorContainer = $(".carousel.slide .carousel-indicators");
  const isLoggedIn = $(".before-login.hide").length !== 0;

  bannerList &&
    bannerList.length !== 0 &&
    bannerList.forEach(function (item, index) {
      const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

      if (imageUrl && redirectGroup !== 1) {
        /**
         * Redirect Group Option
         * 0 - Promo
         * 1 - Join Us
         * 2 - Lottery
         * 3 - Product
         * 4 - Others inner page (Loyalty, Customer Service)
         * 5 - 自定义
         */

        const indicator = $(`
          <li class="${index === 0 ? "active" : ""}" data-target="#main-slider" data-slide-to="${index}"></li>
        `);

        const banner = $(`
          <div
            class="item ${index === 0 ? "active" : ""}"
            style="background-image: url(${imageUrl})"
            ${redirectUrl ? `data-redirect=${redirectUrl}` : ""}
          >
            ${
              index === 1
                ? `<a href='#', data-toggle='modal', data-target='#modal', style='top: 66%;position: absolute;left: 32%;width: 500px;height: 100px;background: transparent;z-index: 100px;'></a>`
                : ""
            }
          </div>
        `);

        /**
         * Banner 2 (index 1) is special for App download modal
         */
        if (index !== 1) {
          banner.click(() => {
            if (redirectGroup === 4 && redirectUrl === "/faq") {
              location.href = "common-problem.html";
            } else {
              window.initBannerCarouselRedirection({
                redirectUrl,
                redirectGroup,
                redirectPage,
                isLoggedIn,
              });
            }
          });
        }

        /**
         * index append 首页type banner, promo append 优惠活动type banner
         * 轮播图类型 bannerType
         * 0 - 首页
         * 1 - 优惠活动
         * 2 - 其他
         */
        if (bannerType === 0) {
          indexContainer.append(banner);
          indicatorContainer.append(indicator);
        }
      }
    });

  if (bannerList && bannerList.length) {
    $("#main-slider .carousel").carousel({
      interval: 8000,
    });
  }
}

$(document).ready(function () {
  //#main-slider
  if (Pace) {
    Pace.on("done", initBannerCarousel);
  } else {
    $(window).load(initBannerCarousel);
  }

  // accordian
  $(".accordion-toggle").on("click", function () {
    $(this)
      .closest(".panel-group")
      .children()
      .each(function () {
        $(this).find(">.panel-heading").removeClass("active");
      });

    $(this).closest(".panel-heading").toggleClass("active");
  });

  //Initiat WOW JS
  new WOW().init();

  // portfolio filter
  // $(window).load(function() {
  //   var $portfolio_selectors = $(".portfolio-filter >li>a");
  //   var $portfolio = $(".portfolio-items");
  //   $portfolio.isotope({
  //     itemSelector: ".portfolio-item",
  //     layoutMode: "fitRows"
  //   });
  //
  //   $portfolio_selectors.on("click", function() {
  //     $portfolio_selectors.removeClass("active");
  //     $(this).addClass("active");
  //     var selector = $(this).attr("data-filter");
  //     $portfolio.isotope({ filter: selector });
  //     return false;
  //   });
  // });

  // Contact form
  // var form = $("#main-contact-form");
  // form.submit(function(event) {
  //   event.preventDefault();
  //   var form_status = $('<div class="form_status"></div>');
  //   $.ajax({
  //     url: $(this).attr("action"),
  //
  //     beforeSend: function() {
  //       form.prepend(form_status.html('<p><i class="fa fa-spinner fa-spin"></i> Email is sending...</p>').fadeIn());
  //     }
  //   }).done(function(data) {
  //     form_status
  //       .html('<p class="text-success">' + data.message + "</p>")
  //       .delay(3000)
  //       .fadeOut();
  //   });
  // });

  //goto top
  $(".gototop").click(function (event) {
    event.preventDefault();
    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      500
    );
  });

  //Pretty Photo
  $("a[rel^='prettyPhoto']").prettyPhoto({
    social_tools: false,
  });

  $(document).on("click", ".sub-content-nav .nav li, .app-download-nav .nav li", function (e) {
    e.preventDefault();
    var index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".tab-pane").eq(index).show().siblings().hide();
  });

  // promo
  if (window.promo) {
    renderPromo(window.promo);
  } else {
    Object.defineProperty(window, "promo", {
      set: function (data) {
        renderPromo(data);
      },
    });
  }

  function registerPromo(promoId) {
    api.registerPromo(promoId);
  }

  function renderPromo(promoData) {
    if (Array.isArray(promoData)) {
      var promoWrapper = $(`<div class="promo-wrapper"></div>`);
      promoData.forEach(function (value, index) {
        var promoItem = $(`<div class="item" data-type="${value.type}"></div>`);
        var promoId = value.promoId;
        var requestToJoin = value.requestToJoin;
        var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

        promoItem.append(
          $(`
            ${
              value.imagePath
                ? `
            <img class="item-head image" src="${value.imagePath}" alt="${value.title}"/>
            `
                : `
            <h3 class="item-head">
                  <div class="text flex">
                    <h1>${value.title}</h1>
                    <div class="left-pattern"></div>
                    <div class="right-pattern"></div>
                  </div>
                </h3>
            `
            }
            <div class="item-content">
              <h3>${value.title}</h3>
              ${value.content}
              ${
                requestToJoin
                  ? `
                       <div
                         class="item-footer"
                         style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;font-size: 14px"
                       >
                         ${
                           firstDepositPromoJoinMsg
                             ? `
                               <div class="item-footer--info" style="color: #555;">
                                 ${firstDepositPromoJoinMsg}
                               </div>
                             `
                             : `
                               <button
                                 class="item-footer--register-promo"
                                 data-id="${promoId}"
                                 style="background-image: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);cursor:pointer;color:#fff;outline: 0;border:0;height:36px; width:120px;"
                               >
                                 申请参与
                               </button>
                             `
                         }
                       </div>
                     `
                  : ``
              }
            </div>`)
        );

        promoWrapper.append(promoItem);
      });

      $(".promo-content").on("click", ".item-footer--register-promo", function (e) {
        e.preventDefault();
        var promoId = $(this).data("id");
        registerPromo(promoId);
      });

      $(".promo-content .page").html(promoWrapper);
      loadPromoAnimation();
    }
  }

  $(".page.promo").on("click", ".content-footer--register-promo", function (e) {
    e.preventDefault();
    var promoId = $(this).data("id");
    registerPromo(promoId);
  });

  function loadPromoAnimation() {
    // if ($(".promo-content .page .sub-content.promo").length !== 0) {
    $(".promo-content .page .item").each(function (k, i) {
      $(i).removeClass("ready");
      setTimeout(function () {
        $(i).addClass("ready");
      }, (k + 1) * 150);
    });
    // }
  }

  $(document).on("click", ".item > .item-head", function () {
    $(this).next().stop().slideToggle();
  });
});
